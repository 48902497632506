import React from "react"
import { graphql } from "gatsby"

import BgImage from "../components/BgImage"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Liner from "../components/Liner"
import MastWrap from "../components/MastWrap"
import PageHeading from "../components/PageHeading"

export default function Home({ data }) {
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 769px)`,
    },
  ]
  return (
    <Layout>
      <MastWrap>
        <section>
          <div>
            <BgImage
              title="hero"
              fluid={sources}
              imgStyle={{ objectFit: "contain" }}
              height="100vh"
              overlayColor="#04040454"
            >
              <PageHeading heading="about" />
            </BgImage>
          </div>
        </section>

        <section className="sub-wrap pad-top pad-bottom about white-bg">
          <div className="container">
            <div className="row">
              <article className="col-md-6 text-left caption">
                <h1 className="font2 black">
                  Professional Makeup Artist &amp; Hair Stylist
                </h1>
                <Liner />
                <h3 className="font4 grey">
                  with more than 15 years of industry experience, specialising
                  in bridal parties, fashion, beauty, and mainstream television
                  productions
                </h3>
              </article>
              <article className="col-md-6 text-left">
                <p className="font4">
                  Working in the fashion and television industries means that I
                  have hands-on experience with the most up-to-date styles and
                  colours to create the look that you want and that you will
                  love. The greatest care and attention to detail is taken to
                  make sure that you look perfect.
                  <br />
                  <br /> I know you will have an amazing styling experience that
                  you will treasure, as I like to create a relaxed and happy
                  environment to ensure it is just as memorable as the rest of
                  your special day.
                  <br />
                  <br />
                  As a freelancer I am not restricted to any one cosmetic or
                  hair-care range so I always use high quality products to suit
                  each skin and hair type. My experience with using many ranges
                  of products enables me to pick out the best from each brand
                  and create a look that is to your individual needs.
                  <br />
                  <br />
                  The Service I provide is available in and around London and
                  the Greater London areas, including North Kent, but other
                  locations can be considered.
                </p>
              </article>
            </div>
          </div>
        </section>
        <Footer />
      </MastWrap>
    </Layout>
  )
}

export const query = graphql`
  query About {
    mobileImage: file(relativePath: { eq: "bg/12.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
    desktopImage: file(relativePath: { eq: "bg/12-desktop.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`
